import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import {
  mainBlack,
  mainRed,
  mainYellow,
  mainWhite,
  transHover,
  innerWidth,
  lightGrey,
  screen,
} from "../components/common/variables";
import { getFirstNameWithGreet } from "../helpers";
import IconSwirl from "../images/svg/icon-swirl.svg";
import Slider from "react-slick";
import { BsArrowRight } from "react-icons/bs";
import SEO from "../components/seo";
import StyledButton from "../components/styled-button";
import MainBanner from "../components/banner/main";
import SubBanner from "../components/banner/sub";
import Member from "../components/member";
import ModalMember from "../components/modal-member";
import FallbackImgSquare from "../components/fallback-images/fallback-person-member-img-square";

const Wrapper = styled.div`
  color: ${mainBlack};
  background: ${mainWhite};
  min-height: 100vh;

  .banner {
    position: relative;
    border-top: 22px ${mainWhite} solid;
    border-bottom: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
      border-bottom: 17px ${mainWhite} solid;
    }

    .featured-txt {
      .inner-txt {
        .bold-p {
          margin: 15px 0 0 0;
          @media ${screen.small} {
            max-width: 530px;
          }
        }
      }
    }
  }

  .inspire {
    position: relative;
    @media ${screen.xsmall} {
      margin: 0;
    }

    .featured-txt {
      color: ${mainWhite};

      .inner-txt {
        .long-subheading {
          text-align: center;
          @media ${screen.small} {
            max-width: 500px;
            text-align: left;
          }

          p {
            font-weight: 600;
            line-height: 1.45;
            @media ${screen.xsmall} {
              font-size: 1.5rem;
            }
            @media ${screen.large} {
              font-size: 1.9rem;
            }
          }

          strong {
            font-weight: 600;
            color: ${mainYellow};
          }
        }

        .normal-p {
          text-align: center;
          margin: 20px 0 0 0;
          @media ${screen.small} {
            max-width: 530px;
            text-align: left;
          }

          p {
            font-size: 1rem;
            font-weight: 300;
            margin: 0 0 20px 0;
            @media ${screen.large} {
              font-size: 1.1rem;
            }

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .why-support {
    position: relative;

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        align-items: center;
        flex-direction: row;
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
        }

        .capital-subheading {
          margin: 0 0 22px 0;
          text-align: center;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }
          @media ${screen.small} {
            text-align: left;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        &--right {
          @media ${screen.small} {
            margin: 0 0 0 10px;
          }

          .bordered-p {
            border-bottom: 2px ${mainRed} solid;
            margin: 0 0 25px 0;
            padding: 0 0 25px 0;
            text-align: center;
            @media ${screen.xsmall} {
              padding: 0 0 50px 0;
              margin: 0 0 50px 0;
              text-align: left;
            }

            p {
              font-size: 1.22rem;
              font-weight: 400;
              @media ${screen.xsmall} {
                font-size: 1.4rem;
              }
              @media ${screen.large} {
                font-size: 1.5rem;
              }
            }

            strong {
              font-weight: 600;
            }

            &:last-child {
              border-bottom: none;
              padding: 0;
              margin: 0;
            }
          }
        }

        .btn {
          display: none;
          @media ${screen.small} {
            display: block;
          }

          &--mobile {
            display: block;
            text-align: center;
            margin: 30px 0 0 0;
            @media ${screen.small} {
              display: none;
            }
          }
        }
      }
    }

    .icon-decor {
      position: absolute;
      display: block;
      top: -52px;
      right: -38px;
      @media ${screen.small} {
        bottom: auto;
        right: auto;
        top: -62px;
        left: -35px;
      }

      svg {
        width: 85px;
        transform: scale(-1) rotate(-18deg);
        @media ${screen.xsmall} {
          transform: scale(1) rotate(0);
        }
        @media ${screen.small} {
          width: 138px;
        }
      }
    }

    .dl-link {
      margin: 40px auto 40px auto;
      display: table;
      @media ${screen.xsmall} {
        margin: 50px auto 50px auto;
      }
      @media ${screen.small} {
        margin: 60px 0 0 0;
      }
    }
  }

  .history {
    background: ${mainBlack};
    text-align: center;

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 22px 50px 22px;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }
    }

    &__heading {
      color: ${mainYellow};
      text-transform: uppercase;
      font-size: 1.25rem;
      font-weight: 600;
      display: block;
      margin: 0 0 16px 0;
      letter-spacing: 2px;
      @media ${screen.xsmall} {
        font-size: 1.35rem;
        margin: 0 0 34px 0;
      }
      @media ${screen.large} {
        font-size: 1.5rem;
        margin: 0 0 38px 0;
      }
    }

    &__description {
      color: ${mainWhite};
      font-size: 1.55rem;
      font-weight: 400;
      line-height: 1.23;
      @media ${screen.xsmall} {
        font-size: 2.2rem;
        margin: 0 auto;
        max-width: 1200px;
      }
      @media ${screen.small} {
        font-size: 2.5rem;
      }
      @media ${screen.large} {
        font-size: 2.8rem;
      }
    }
  }

  .achievements {
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 22px 85px 22px;
      @media ${screen.xsmall} {
        padding: 100px 42px 50px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .capital-subheading {
        margin: 0 0 37px 0;
        text-align: center;
        @media ${screen.xsmall} {
          margin: 0 0 60px 0;
        }
        @media ${screen.large} {
          margin: 0 0 70px 0;
        }
      }

      .slick-slider {
        position: relative;

        .slick-list {
          .slick-track {
            .slick-slide {
              div {
                outline: none;

                .each-item {
                  text-align: center;
                  @media ${screen.xsmall} {
                    padding: 0 30px;
                    text-align: left;
                  }
                  @media ${screen.small} {
                    padding: 0 36px;
                  }
                  @media ${screen.large} {
                    padding: 0 46px;
                  }

                  &__heading {
                    font-size: 1rem;
                    font-weight: 600;
                    margin: 0 0 8px 0;
                    display: block;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    max-width: 350px;
                    @media ${screen.xsmall} {
                      margin: 0 0 10px 0;
                    }
                    @media ${screen.large} {
                      font-size: 1.1rem;
                    }
                  }

                  &__description {
                    border-top: 2px ${mainYellow} solid;
                    padding: 14px 0 0 0;

                    p {
                      font-size: 1rem;
                      font-weight: 300;
                      margin: 0 0 18px 0;
                      @media ${screen.large} {
                        font-size: 1.1rem;
                        padding: 0 15px 0 0;
                      }

                      &:last-child {
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .arrow-btn {
          position: absolute;
          bottom: -62px;
          transform: translate(-50%, 0);
          cursor: pointer;
          @media ${screen.xsmall} {
            top: 50%;
            transform: translate(0, -50%);
            bottom: auto;
          }

          svg {
            color: ${mainRed};
            font-size: 2.4rem;
            transition: ${transHover};
            @media ${screen.xsmall} {
              font-size: 2.1rem;
            }
            @media ${screen.large} {
              font-size: 2.75rem;
            }
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainYellow};
              }
            }
          }

          &--prev {
            left: calc(50% - 36px);
            @media ${screen.xsmall} {
              left: -21px;
            }
            @media ${screen.small} {
              left: -15px;
            }
            @media ${screen.large} {
              left: -39px;
            }

            svg {
              transform: scaleX(-1);
            }
          }

          &--next {
            left: calc(50% + 36px);
            @media ${screen.xsmall} {
              right: -21px;
              left: auto;
            }
            @media ${screen.small} {
              right: -15px;
            }
            @media ${screen.large} {
              right: -39px;
            }
          }
        }
      }
    }
  }

  .board-members {
    background: ${mainWhite};

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        align-items: center;
        padding: 100px 30px;
        flex-direction: row;
      }

      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        @media ${screen.small} {
          width: 43%;
        }

        .capital-subheading {
          margin: 0 0 22px 0;
          text-align: center;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }
          @media ${screen.small} {
            text-align: left;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        .normal-p {
          margin: 20px 0 0 0;
          text-align: center;
          @media ${screen.xsmall} {
            padding: 0 40px 0 0;
            text-align: left;
          }
          @media ${screen.medium} {
            padding: 0 60px 0 0;
          }
          @media ${screen.large} {
            padding: 0 80px 0 0;
          }

          p {
            font-size: 1rem;
            font-weight: 300;
            margin: 0 0 20px 0;
            @media ${screen.large} {
              font-size: 1.1rem;
            }

            &:last-child {
              margin: 0;
            }
          }
        }

        &--right {
          margin: 30px 0 0 0;
          @media ${screen.small} {
            margin: 0;
            width: 57%;
          }

          .board-member-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            @media ${screen.xsmall} {
              flex-direction: row;
              margin: 0 -8px;
            }

            &__each {
              margin: 0 0 38px 0;
              cursor: pointer;
              width: 100%;
              @media ${screen.xsmall} {
                width: calc(33.33% - 16px);
                margin: 8px 8px 38px 8px;
              }
              @media ${screen.small} {
                margin: 8px;
              }
              @media ${screen.medium} {
                width: calc(25% - 16px);
              }

              .secondary-btn {
                @media ${screen.small} {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .donate {
    .inner-wrapper {
      .featured-txt {
        .inner-txt {
          text-align: center;
          @media ${screen.small} {
            text-align: left;
          }

          .bold-p {
            color: ${mainBlack};
            max-width: 500px;
          }

          .red-heading {
            margin: 15px 0;
          }
        }
      }
    }
  }
`;

// custom arrows for react-slick
const PrevArrow = (props) => {
  const { onClick } = props;

  return (
    <span className="arrow-btn arrow-btn--prev" onClick={onClick}>
      <BsArrowRight />
    </span>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;

  return (
    <span className="arrow-btn arrow-btn--next" onClick={onClick}>
      <BsArrowRight />
    </span>
  );
};

// react-slick package settings
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  adaptiveHeight: false,
  slidesToScroll: 3,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const AboutUsPage = ({ data }) => {
  // current member selected state, will open the modal if not null
  const [selectedMember, setSelectedMember] = useState(null);

  const {
    title_tag,
    meta_description,
    banner_heading,
    banner_description,
    banner_image,
    inspire_subheading,
    inspire_description,
    inspire_image,
    support_heading,
    top_description,
    middle_description,
    bottom_description,
    history_heading,
    history_description,
    achievements_heading,
    achievement_list,
    board_members_heading,
    board_members_description,
    members,
    donate_heading,
    donate_subheading,
    donate_image,
  } = data.content.data;

  // top 3 member list to display on top of the list
  const topThreeMembers = members.slice(0, 3);

  // the rest of the member list
  const allOtherMembers = members.slice(3);

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <MainBanner className="banner" bgUrl={banner_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <h6 className="red-heading">{banner_heading.text}</h6>
                <p className="bold-p">{banner_description}</p>
              </div>
            </div>
          </div>
        </MainBanner>

        <SubBanner className="inspire" bgUrl={inspire_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <div
                  className="long-subheading"
                  dangerouslySetInnerHTML={{
                    __html: inspire_subheading.html,
                  }}
                />
                <div
                  className="normal-p"
                  dangerouslySetInnerHTML={{
                    __html: inspire_description.html,
                  }}
                />
              </div>
            </div>
          </div>
        </SubBanner>

        <section className="why-support">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">{support_heading.text}</h3>
              <StyledButton className="btn" to="/sponsor-us/">
                BECOME A SPONSOR
              </StyledButton>
            </div>

            <div className="col col--right">
              <div>
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{ __html: top_description.html }}
                />
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{
                    __html: middle_description.html,
                  }}
                />
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{
                    __html: bottom_description.html,
                  }}
                />
              </div>
              <StyledButton
                className="dl-link"
                href="https://sydneystreetchoir.org.au/pdf/Sydney-Street-Choir-Impact-Data.pdf"
              >
                DOWNLOAD EDUCATIONAL RESOURCE
              </StyledButton>
              <StyledButton className="btn btn--mobile" to="/sponsor-us/">
                BECOME A SPONSOR
              </StyledButton>
            </div>
          </div>
          <span className="icon-decor">
            <IconSwirl />
          </span>
        </section>

        <section className="history">
          <div className="inner-wrapper">
            <h5 className="history__heading">{history_heading.text}</h5>
            <p className="history__description">{history_description}</p>
          </div>
        </section>

        <section className="achievements">
          <div className="inner-wrapper">
            <h3 className="capital-subheading">{achievements_heading.text}</h3>
            <Slider {...settings}>
              {achievement_list.map((item, i) => (
                <div className="each-item" key={i}>
                  <h6 className="each-item__heading">{item.title}</h6>
                  <div
                    className="each-item__description"
                    dangerouslySetInnerHTML={{
                      __html: item.description.html,
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </section>

        <section className="board-members">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">
                {board_members_heading.text}
              </h3>
              <div
                className="normal-p"
                dangerouslySetInnerHTML={{
                  __html: board_members_description.html,
                }}
              />
            </div>
            <div className="col col--right">
              <ul className="board-member-list board-member-list--top">
                {topThreeMembers.map((item, i) => (
                  <Member
                    className="board-member-list__each"
                    key={i}
                    firstName={getFirstNameWithGreet(item.name.text)}
                    onClick={() => {
                      setSelectedMember({
                        name: item.name.text,
                        title: item.scc_position_role,
                        other_title: item.other_position_role,
                        description: item.description.html,
                        image: item.photo.gatsbyImageData,
                      });
                    }}
                  >
                    <div
                      className="photo"
                      onClick={() => {
                        setSelectedMember({
                          name: item.name.text,
                          title: item.scc_position_role,
                          other_title: item.other_position_role,
                          description: item.description.html,
                          image: item.photo.gatsbyImageData,
                        });
                      }}
                    >
                      {item.photo.thumbnails.thumbnail.gatsbyImageData ? (
                        <GatsbyImage
                          image={
                            item.photo.thumbnails.thumbnail.gatsbyImageData
                          }
                          alt={item.photo.alt || "Portrait"}
                        />
                      ) : (
                        <FallbackImgSquare />
                      )}
                    </div>
                  </Member>
                ))}
              </ul>
              <ul className="board-member-list">
                {allOtherMembers.map((item, i) => (
                  <Member
                    className="board-member-list__each"
                    key={i}
                    firstName={getFirstNameWithGreet(item.name.text)}
                    onClick={() => {
                      setSelectedMember({
                        name: item.name.text,
                        title: item.scc_position_role,
                        other_title: item.other_position_role,
                        description: item.description.html,
                        image: item.photo.gatsbyImageData,
                      });
                    }}
                  >
                    <div className="photo">
                      {item.photo.thumbnails.thumbnail.gatsbyImageData ? (
                        <GatsbyImage
                          image={
                            item.photo.thumbnails.thumbnail.gatsbyImageData
                          }
                          alt={item.photo.alt || "Portrait"}
                        />
                      ) : (
                        <FallbackImgSquare />
                      )}
                    </div>
                  </Member>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <SubBanner
          className="donate"
          bgUrl={donate_image.url}
          bgColour={lightGrey}
          bgLeft={true}
        >
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <p className="bold-p">{donate_heading}</p>
                <p className="red-heading">{donate_subheading}</p>
                <StyledButton className="btn" to="/donate/">
                  DONATE
                </StyledButton>
              </div>
            </div>
          </div>
        </SubBanner>

        <ModalMember
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
        />
      </Wrapper>
    </Layout>
  );
};

export default AboutUsPage;

export const dataQuery = graphql`
  {
    content: prismicAboutUsPage {
      data {
        title_tag
        meta_description
        banner_heading {
          text
        }
        banner_description
        banner_image {
          alt
          url
        }
        inspire_subheading {
          html
        }
        inspire_description {
          html
        }
        inspire_image {
          alt
          url
        }
        support_heading {
          text
        }
        top_description {
          html
        }
        middle_description {
          html
        }
        bottom_description {
          html
        }
        history_heading {
          text
        }
        history_description
        achievements_heading {
          text
        }
        achievement_list {
          title
          description {
            html
          }
        }
        achievement_list {
          title
          description {
            html
          }
        }
        board_members_heading {
          text
        }
        board_members_description {
          html
        }
        members {
          name {
            text
          }
          other_position_role
          ssc_position_role
          description {
            html
          }
          photo {
            alt
            gatsbyImageData(placeholder: BLURRED)
            thumbnails {
              thumbnail {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        donate_heading
        donate_subheading
        donate_image {
          alt
          url
        }
      }
    }
  }
`;
