// styled box member
import React from "react";
import styled from "styled-components";
import {
  mainWhite,
  mainBlack,
  transHover,
  screen,
} from "../../components/common/variables";

const Wrapper = styled.li`
  position: relative;
  color: ${mainWhite};
  @media ${screen.withCursor} {
    &:hover .overlay {
      opacity: 1;
      pointer-events: all;
    }
  }

  .overlay {
    display: none;
    @media ${screen.medium} {
      position: absolute;
      background: ${mainBlack};
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      transition: ${transHover};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 22px 11px;
      text-align: center;
      cursor: pointer;
    }

    &__name {
      font-size: 1.2rem;
      font-weight: 600;
      letter-spacing: 1px;
      margin: 0 0 22px 0;
      text-transform: uppercase;
    }

    &__btn {
      cursor: pointer;
      color: ${mainWhite};
      background: none;
      border: 1px ${mainWhite} solid;
      border-radius: 3px;
      font-size: 1.02rem;
      letter-spacing: 2px;
      padding: 10px;
      text-transform: uppercase;
    }
  }

  .secondary-name {
    color: ${mainBlack};
    font-size: 1.1rem;
    text-align: center;
    margin: 14px 0 0 0;
    padding: 0 8px;
    @media ${screen.small} {
      display: none;
    }
  }

  .secondary-btn {
    cursor: pointer;
    color: ${mainBlack};
    background: none;
    border: 2px ${mainBlack} solid;
    border-radius: 3px;
    font-size: 1.02rem;
    letter-spacing: 1px;
    padding: 13px 23px 12px 23px;
    text-transform: uppercase;
    display: block;
    font-size: 1.02rem;
    font-weight: 600;
    border-radius: 5px;
    letter-spacing: 3px;
    display: table;
    min-width: 200px;
    margin: 30px auto 0 auto;
    line-height: 20px;
    @media ${screen.xsmall} {
      margin: 16px auto 0 auto;
    }
    @media ${screen.medium} {
      display: none;
      margin: 0;
    }
  }
`;

const Member = ({ children, className, firstName, fullName, onClick }) => {
  return (
    <Wrapper className={className} onClick={onClick}>
      {children}
      {fullName && <h6 className="secondary-name">{fullName}</h6>}
      <button className="secondary-btn" onClick={onClick}>
        {firstName}
      </button>
    </Wrapper>
  );
};

export default Member;
