// member modal
import React, { useEffect } from "react";
import styled from "styled-components";
import { ScrollLocky } from "react-scroll-locky";
import {
  mainRed,
  mainBlack,
  screen,
  lightGrey,
  mainYellow,
  mainWhite,
  siteWidth,
} from "../common/variables";
import FallbackImgSquare from "../../components/fallback-images/fallback-person-member-img-square";
import BackgroundImage from "../background-image";

const Wrapper = styled.div`
  background: rgba(43, 43, 43, 0.92);
  color: ${mainBlack};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;

  .modal {
    display: flex;
    flex-direction: column;
    height: calc(100% - 54px);
    width: calc(100% - 34px);
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: ${siteWidth};
    transform: translate(-50%, -50%);
    @media ${screen.xsmall} {
      height: calc(100% - 100px);
      width: calc(100% - 100px);
    }
    @media ${screen.small} {
      flex-direction: row;
      height: calc(100% - 100px);
      width: calc(100% - 100px);
    }
    @media ${screen.medium} {
      height: calc(100% - 125px);
      width: calc(100% - 125px);
    }
    @media ${screen.large} {
      height: calc(100% - 150px);
      width: calc(100% - 150px);
    }
    @media ${screen.xlarge} {
      height: calc(100% - 250px);
      width: calc(100% - 250px);
    }

    &__txt {
      background: ${mainWhite};
      width: 100%;
      height: 65%;
      padding: 40px 22px 24px 22px;
      text-align: left;
      @media ${screen.xsmall} {
        padding: 50px;
        height: 55%;
      }
      @media ${screen.small} {
        padding: 50px;
        width: 50%;
        height: 100%;
        text-align: left;
      }
      @media ${screen.medium} {
        padding: 70px;
      }
      @media ${screen.large} {
        padding: 120px 100px 100px 100px;
      }

      .name {
        font-size: 1.84rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.2;
        @media ${screen.xsmall} {
          font-size: 2.7rem;
        }
        @media ${screen.small} {
          font-size: 3rem;
        }
      }

      .other-title {
        font-weight: 600;
        font-size: 0.95rem;
        padding: 0;
        @media ${screen.xsmall} {
          font-size: 1rem;
        }
        @media ${screen.small} {
          font-size: 1.1rem;
        }
      }

      .title {
        color: ${mainRed};
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 3px;
        line-height: 1.4;
        text-transform: uppercase;
        margin: 13px 0 12px 0;
        @media ${screen.xsmall} {
          font-size: 1.25rem;
        }
        @media ${screen.large} {
          font-size: 1.42rem;
        }
      }

      .description {
        overflow: auto;
        padding: 0 20px 0 0;
        height: calc(214px + 2vw);
        @media ${screen.xsmall} {
          padding: 0 30px 0 0;
          height: calc(100px + 10vh + 10vw);
        }

        ::-webkit-scrollbar {
          width: 9px;
        }

        ::-webkit-scrollbar-thumb {
          outline: 1px solid #fff;
          background: ${lightGrey};
          border-radius: 10px;
        }

        p {
          font-size: 1rem;
          font-weight: 300;
          margin: 0 0 21px 0;
          @media ${screen.xsmall} {
            margin: 0 0 28px 0;
          }
          @media ${screen.small} {
            margin: 0 0 34px 0;
          }
          @media ${screen.large} {
            font-size: 1.1rem;
          }
        }

        a {
          color: ${mainBlack};
          text-decoration: underline;
          font-weight: 400;
          @media ${screen.withCursor} {
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }

    &__img {
      width: 100%;
      height: 35%;
      @media ${screen.xsmall} {
        height: 45%;
      }
      @media ${screen.small} {
        width: 50%;
        height: 100%;
      }

      .bg {
        height: 100%;
      }
    }

    &__close-btn {
      background: none;
      cursor: pointer;
      border: none;
      border-radius: none;
      position: absolute;
      font-size: 2rem;
      font-weight: 700;
      top: 10px;
      right: 13px;
      color: ${mainYellow};
      outline: none;
      padding: 0;
      line-height: 30px;
      @media ${screen.xsmall} {
        font-size: 2.5rem;
        top: 14px;
        right: 13px;
      }
      @media ${screen.large} {
        font-size: 3rem;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainRed};
        }
      }
    }
  }
`;

const ModalMember = ({ selectedMember, setSelectedMember }) => {
  useEffect(() => {
    // close modal if user clicked outside
    const userClick = (e) => {
      if (e.target.id === "outter-bg-member") {
        setSelectedMember(null);
      }
    };

    // close modal if user pressed ESC key
    const userPress = (e) => {
      if (e.keyCode === 27) {
        setSelectedMember(null);
      }
    };

    window.addEventListener("click", userClick);

    window.addEventListener("keyup", userPress);

    return () => {
      window.removeEventListener("click", userClick);
      window.removeEventListener("keyup", userPress);
    };
  }, []);

  // only show modal if selectedMember not null
  return (
    selectedMember && (
      <ScrollLocky>
        <Wrapper
          isOtherTitle={selectedMember.other_title}
          id="outter-bg-member"
        >
          <div className="modal">
            <div className="modal__txt">
              <h5 className="name">{selectedMember.name}</h5>
              {selectedMember.other_title && (
                <p className="other-title">{selectedMember.other_title}</p>
              )}

              <p className="title">{selectedMember.title}</p>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: selectedMember.description }}
              />
            </div>

            <div className="modal__img">
              {selectedMember.image ? (
                <BackgroundImage className="bg" bgUrl={selectedMember.image} />
              ) : (
                <FallbackImgSquare className="bg" />
              )}
            </div>
            <button
              className="modal__close-btn"
              onClick={() => setSelectedMember(null)}
            >
              X
            </button>
          </div>
        </Wrapper>
      </ScrollLocky>
    )
  );
};

export default ModalMember;
