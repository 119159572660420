import React from "react";
import styled from "styled-components";
import { mainWhite } from "../common/variables";

const Wrapper = styled.div`
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${mainWhite};
`;

const BackgroundImage = ({ className, bgUrl }) => {
  return <Wrapper className={className} bgUrl={bgUrl}></Wrapper>;
};

export default BackgroundImage;
