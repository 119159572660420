// banner used for home page and about page
import React from "react";
import styled from "styled-components";
import {
  mainBlack,
  mainWhite,
  screen,
  innerWidth,
} from "../../components/common/variables";
import BackgroundImage from "../background-image";

const Wrapper = styled.div`
  position: relative;
  background: ${mainWhite};
  color: ${mainBlack};

  .sub-wrapper {
    display: flex;
    flex-direction: column;
    @media ${screen.small} {
      display: block;
    }
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 41px 22px 50px 22px;
    display: flex;
    flex-direction: column;
    @media ${screen.xsmall} {
      padding: 100px 42px;
    }
    @media ${screen.small} {
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      padding: 100px 30px;
      min-height: 610px;
    }
    @media ${screen.large} {
      padding: 110px 0;
    }
    @media ${screen.xlarge} {
      min-height: 700px;
    }

    .featured-txt {
      position: relative;
      z-index: 2;
      text-align: center;
      @media ${screen.small} {
        width: 49%;
        text-align: left;
      }

      .inner-txt {
        @media ${screen.small} {
          margin: 0 0 0 10%;
        }
      }
    }
  }

  .absolute-bg {
    height: 255px;
    order: -1;
    @media ${screen.xsmall} {
      height: 480px;
    }
    @media ${screen.small} {
      width: 51%;
      position: absolute;
      right: auto;
      left: 0;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      order: 0;
    }
  }
`;

const MainBanner = ({ children, className, bgUrl, bgColour }) => {
  return (
    <Wrapper className={className} bgColour={bgColour}>
      <div className="sub-wrapper">
        {children}
        <BackgroundImage className="absolute-bg" bgUrl={bgUrl} />
      </div>
    </Wrapper>
  );
};

export default MainBanner;
